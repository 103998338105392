@tailwind base;
@tailwind components;
@tailwind utilities;
/* Chatbot Icon Container */
.chatbot-icon1 {
  position: fixed !important;
  bottom: 20px !important;
  right: 20px !important;
  z-index: 1002 !important; /* Higher z-index when closed */
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, z-index 0s 0.3s !important; /* Smooth transition */
  animation: bounceIn 2s ease-in-out !important;
  cursor: pointer;
  pointer-events: auto !important;
}

/* Whistle Chat Window */
#w-live-chat {
  position: fixed !important;
  bottom: 14px !important;
  right: 14px !important;
  z-index: 999 !important; /* Lower z-index by default */
  pointer-events: none !important; /* No interaction when closed */
  display: none; /* Completely hide the chat window when closed */
  transition: display 0.3s, opacity 0.3s ease-in-out; /* Smooth transition for open/close */
}

#w-live-chat.open {
  z-index: 1002 !important; /* Higher z-index when open */
  pointer-events: auto !important; /* Interaction enabled when open */
  display: block !important; /* Make the chat window visible */
}

#w-live-chat iframe {
  pointer-events: auto !important; /* Ensure iframe is interactable */
}

/* Chatbot Icon */
.chatbot-icon {
  width: 60px !important;
  height: 60px !important;
  display: inline-block !important;
  vertical-align: middle !important;
  transition: opacity 0.3s ease-in-out !important; /* Smooth transition for hiding/showing */
}

/* Bounce animation for the chatbot icon */
@keyframes bounceIn {
  0%, 20%, 40%, 60%, 80%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  70% {
    transform: translateY(10px);
  }
  90% {
    transform: translateY(-5px);
  }
}
.chatbot-icon1:hover .chatbot-icon {
  transform: scale(1.1) !important;
  transition: transform 0.2s ease-in-out !important;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .chatbot-icon1 {
    z-index: 900 !important;
  }
}